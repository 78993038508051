import { Box, Text, Heading } from "@chakra-ui/react"
import React from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"

import "../../style/swiper.css"

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core"

import { StaticImage } from "gatsby-plugin-image"

// install Swiper modules
SwiperCore.use([Pagination])

const Strategies = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8}>
      {/* title  */}
      <section id="Strategies">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0" letterSpacing="tight">
            協
          </Box>
          會介紹
        </Heading>
      </section>

      <Box pt={{ base: "10" }} pb={{ base: "10" }}>
        <br />
        <Text>
          澳門元宇宙產業協會（MIAM）致力於支持元宇宙產業的發展，促進相關教育及創新活動，建立和鼓勵社群互動，與志同道合者們共同探討元宇宙所帶來的技術與文化革新。
        </Text>
        <br />
      </Box>
    </Box>
  )
}

export default Strategies
