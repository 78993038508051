import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Box, Heading, Flex, Icon } from "@chakra-ui/react"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Layout from "../components/layout"
import Banner from "../components/traditionalChinese/banner"
import Strategies from "../components/traditionalChinese/strategies"
import Portfolios from "../components/traditionalChinese/portfolios"
import AboutUs from "../components/traditionalChinese/aboutUs"
import SEO from "react-seo-component"

import Header from "../components/traditionalChinese/header_phone"
import ResponsivePlayer from "../components/minPlayer/minPlayer"
import minvideo from "../assets/minvideo.mp4"

const CN = () => {
  const data = useStaticQuery(graphql`
    {
      placeholderImage: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 825
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      backgroundImage1: file(relativePath: { eq: "bg1.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 490
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const banner = getImage(data.placeholderImage)

  const bg1 = getImage(data.backgroundImage1)

  return (
    <Layout>
      {/* 缩小的手机导航栏 */}
      <Header />

      <SEO
        title={"首頁"}
        titleTemplate={"澳門元宇宙產業協會（MIAM）"}
        titleSeparator={`-`}
        description={
          "澳門元宇宙產業協會（MIAM）致力於支持元宇宙產業的發展；促進相關教育及創新活動，建立和鼓勵社群互動，與志同道合者們共同探討元宇宙所帶來的技術與文化革新。 "
        }
        // image={"https://i.ibb.co/6RWBX1d/Mindfulness-Capital.png"}
        image={"https://i.ibb.co/sJwr3F6/miam-logo.png"}
        pathname={"cn"}
        siteLanguage={"cn"}
        siteLocale="cn_gb"
        twitterUsername={"澳門元宇宙產業協會（MIAM）"}
      />

      <Box mt="4rem" />
      {/* first row  */}
      {/* pc */}
      <Box
        maxW="1100px"
        margin="0 auto"
        padding="0 auto"
        // h={{ md: "36rem" }}
        display={{base: "none",sm: "none",md: "flex"}}
        >
        <ResponsivePlayer url={minvideo} controls={false} playing={true} isCenterVideo={false}/>
      </Box>
      {/* phone */}
      <Box
        maxW="1100px"
        margin="0 auto"
        padding="0 auto"
        // h={{sm:'20rem'}}
        display={{base: "flex",sm:"flex", md: "none"}}
        >
        <ResponsivePlayer url={minvideo} controls={true} playing={false} isCenterVideo={false}/>
      </Box>

      {/* <BgImage image={banner}>
        <Banner />
           <ResponsivePlayer url={minvideo}/>
      </BgImage> */}

      {/* second row  */}
      <Box maxW="1100px" margin="0 auto" mt="4rem">
        <BgImage image={bg1}>
          <Strategies />
        </BgImage>
      </Box>

      {/* third row */}
      <Box maxW="1100px" margin="0 auto">
        <Portfolios />
      </Box>

      {/* fourth row  */}
      <Box maxW="1100px" margin="0 auto">
        <AboutUs />
      </Box>
    </Layout>
  )
}

export default CN
