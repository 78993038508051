import React, { useState } from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
// import { navigate } from "gatsby"
import ResponsivePlayer from "../../components/minPlayer/minPlayer"
import centerVideo from "../../assets/centerVideo.mp4"

export function CompanyBox({ children, nav }) {
  // const [click, setClick] = useState(false)
  return (
    <Box
      cursor="default"
      position="relative"
      // _active={{ boxShadow: "5px 5px 5px #ccc" }}
      // onclick event
      // onClick={() => {
      //   setClick(true)
      //   navigate(nav)
      //   setInterval(() => setClick(false), 150)
      // }}
    >
      {children}
      <Box
        // display={click === true ? "inline" : "none"}
        position="absolute"
        display="none"
        w="20%"
        left="40%"
        bottom="0.5rem"
        borderBottom="2px solid #000"
        zIndex="100"
      ></Box>
    </Box>
  )
}

const Portfolios = () => {
  return (
    <Box maxW="1210px" mx={8}>
      {/* title  */}
      <section id="Portfolio">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0">
            活
          </Box>
          動新聞
        </Heading>
      </section>

      <br />
      <br />
      {/* <StaticImage
        src="../../images/flyer.jpeg"
        alt="A dinosaur"
        placeholder="blurred"
        // layout="fixed"
      /> */}

<Box/>
{/* <Box mt="4rem" /> */}
       {/* first row  */}
      {/* pc */}
      <Box
        maxW="1100px"
        margin="0 auto"
        padding="0 auto"
        // h={{md:'36rem'}}
        display={{base: "none",sm: "none",md: "flex"}}
        >
        <ResponsivePlayer isPhone={false} url={centerVideo} controls={false} playing={true} isCenterVideo={true}/>
      </Box>
      {/* phone */}
      <Box
        maxW="1100px"
        margin="0 auto"
        padding="0 auto"
        // h={{sm:'20rem'}}
        display={{base: "flex",sm:"flex", md: "none"}}
        >
        <ResponsivePlayer isPhone={true} url={centerVideo} controls={true} playing={false} isCenterVideo={true}/>
      </Box>    

      {/* <br /> */}
      <br />
      <br />
      {/* <Heading fontSize="2rem" textAlign="center">
        造夢空間: 元宇宙新浪潮” NFT藝術展
      </Heading> 
      */}
      <Text textAlign="center">2024.04.07</Text>

      <Text textAlign="center"><strong>香港 Web3 Mix & Mingle 酒會</strong> </Text>

      <Text textAlign="center">澳門元宇宙產業協會 x 正念資本</Text>
      <br />
      <br />
      <br />

      <Text textAlign="center">2022.09.08 - 2023.5.30</Text>

      <Text textAlign="center"><strong>造夢空間: 元宇宙新浪潮 NFT藝術展</strong> </Text>

      <Text textAlign="center">澳門元宇宙產業協會 × 澳門巴黎人</Text>

      <Text textAlign="center">媒體報道: www.macaudailytimes.com.mo/miam-holds-nft-art-exhibition-at-the-parisian-macao.html</Text>
      <br />
      <br />
      <br />

      <Text textAlign="center">2022.05.01 - 2022.05.31</Text>

      <Text textAlign="center"><strong>造夢空間: 元宇宙新浪潮 NFT藝術展</strong> </Text>

      <Text textAlign="center">澳門元宇宙產業協會 × H853 Fun Factory 娛樂廠</Text>

      <Text textAlign="center">媒體報道: www.macaonews.org/features/macaos-first-nft-exhibition-brings-blockchain-technology-down-to-earth/</Text>
      <br />
      <br />
      <br />


    </Box>
  )
}

export default Portfolios
