import {
  Box,
  Heading,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { navigate } from "gatsby"

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  return (
    <Flex
      alignItems="center"
      // pt={16}
      pt={1}
      mt={1}
      display={{ base: "flex", sm: "flex", md: "none" }}
      justifyContent="space-around"
    >

      {/* <StaticImage
        src={"../../images/MindfulnessCapital_phone.png"}
        // src={"../../images/header_MindfulnessCapital_phone.png"}
        alt="mindfulnessCapitalphone"
        placeholder="blurred"
        // width={118}
        // height={18}
        height={30}
      /> */}

     <Box
        color="#333333"
        fontWeight="500"
       >
         {"Metaverse Industry Association Macau"}
       </Box>

      {/* <Box w={{ base: 6, sm: 6, md: 6 }} /> */}
      <Box>
        <Flex alignItems="center" fontSize={{ base: "xs", md: "md" }}>
          <Box
            mr={{ md: 1 }}
            color="#999"
            fontWeight="500"
            cursor="pointer"
            onClick={() => {
              navigate("/")
            }}
          >
            EN{" "}
          </Box>
          <Box borderLeft="1px solid #999" h="20px" mx={2} />
          <Box
            ml={{ md: 1 }}
            fontWeight="500"
            color="#999"
            cursor="pointer"
            onClick={() => {
              navigate("/simplifiedChinese")
            }}
          >
            简{" "}
          </Box>
          <Box borderLeft="1px solid #999" h="20px" mx={2} />
          <Box
            ml={{ md: 1 }}
            fontWeight="800"
            // color="#fff"
            color="#000"
            cursor="pointer"
            onClick={() => {
              navigate("/traditionalChinese")
            }}
          >
            繁
          </Box>
          <Box h="20px" mx={2} />
          <Flex
            alignItems="center"
            w="30px"
            h="30px"
            cursor="pointer"
            onClick={onOpen}
          >
            <StaticImage
              src="../../images/ham.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              width={30}
            />
          </Flex>
        </Flex>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent bgColor="#020034">
            <DrawerBody py={87} px={5}>
              <Flex justifyContent="space-between">
                <Box w={2} />
                <Box cursor="pointer" onClick={onClose}>
                  <StaticImage
                    src="../images/close.png"
                    alt="A dinosaur"
                    placeholder="blurred"
                    layout="fixed"
                  />
                </Box>
              </Flex>

              <Box as="nav">
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                  onClick={onClose}
                >
                  首頁
                </Box>
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                >
                  {" "}
                  <AnchorLink href="#Strategies" onClick={onClose}>
                    協會介紹
                  </AnchorLink>
                </Box>
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                >
                  <AnchorLink href="#Portfolio" onClick={onClose}>
                    活動新聞
                  </AnchorLink>
                </Box>
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                >
                  <AnchorLink href="#About" onClick={onClose}>
                    會員介紹
                  </AnchorLink>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </Flex>
  )
}

export default Header
