import { Box, Grid, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const AboutUs = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8} mb={20}>
      {/* title  */}
      <section id="About">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0">
            會
          </Box>
          員介紹
        </Heading>
      </section>
      {/* thomas */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/ThomasAo.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Thomas Ao 歐嘉成
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />
          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            澳門元宇宙產業協會創始人、會長，Thomas作為正念資本創始合夥人和資深投資人，專注於元宇宙、區塊鏈技術、NFT和Web
            3.0等領域的投資。
          </Text>
        </Box>
      </Grid>
      {/* fernando */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/Fernandp.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Fernando Pereira 田錫恩
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            澳門元宇宙產業協會聯合創始人，GROW UP
            ESPORTS創始合伙人，以及國際電子競技聯合會的正式會員和澳門代表。
          </Text>
        </Box>
      </Grid>

      {/* terry */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/TerryYeung.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Terry Yeung 楊東成
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            澳門元宇宙產業協會聯合創始人，帝國科技集團（0776.HK)的執行董事，致力於為全球社群及玩家提供開放的GameFi平台。
          </Text>
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutUs
